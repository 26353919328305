import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { PrimaryButton, Stack } from "office-ui-fabric-react";

const LoginButton = () => {
  const { loginWithRedirect } = useAuth0();
  return (
    <Stack>
      <PrimaryButton
        text="Log In"
        onClick={() => loginWithRedirect()}
        allowDisabledFocus
      />
    </Stack>
  );
};

export default LoginButton;
