import React, { useState } from "react";
import { ThemeProvider } from "@fluentui/react-theme-provider";
import { Toggle } from "@fluentui/react";
import { darkTheme, lightTheme } from "./themes";
import { Stack, Text, FontWeights } from "office-ui-fabric-react";
import { Switch } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import HomePage from "./views/HomePage";
import Story from "./views/Story";
import Profile from "./views/Profile";
import NavBar from "./components/NavBar";
import ProtectedRoute from "./auth/protected-route";
import Loading from "./components/Loading";
import AuthNav from "./components/AuthNav";

const boldStyle = { root: { fontWeight: FontWeights.semibold } };

export const App: React.FunctionComponent = () => {
  const [useDarkMode, setUseDarkMode] = useState(false);

  const { isLoading } = useAuth0();

  if (isLoading) {
    return <Loading />;
  }

  return (
    <ThemeProvider applyTo="body" theme={useDarkMode ? darkTheme : lightTheme}>
      <Stack horizontal>
        <NavBar />
        <Stack
          horizontalAlign="center"
          verticalAlign="end"
          verticalFill
          styles={{
            root: {
              width: "960px",
              margin: "0 auto",
              textAlign: "center",
              color: "#605e5c",
            },
          }}
          gap={15}
        >
          <Text variant="xxLarge" styles={boldStyle}>
            Welcome to the Ellis Family website.
          </Text>
          <div className="wrapper">
            <Switch>
              <ProtectedRoute path="/profile" component={Profile} />
              <ProtectedRoute path="/homepage" component={HomePage} />
              <ProtectedRoute path="/story" component={Story} />
            </Switch>
          </div>
          <Stack horizontal>
            <Toggle
              label="Change themes"
              onText="Dark Mode"
              offText="Light Mode"
              onChange={() => setUseDarkMode(!useDarkMode)}
            />
          </Stack>
        </Stack>
        <AuthNav />
      </Stack>
    </ThemeProvider>
  );
};
