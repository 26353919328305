import React from "react";
import { Stack, Text, Link, FontWeights } from "office-ui-fabric-react";
import george from "../resources/george.png";

const boldStyle = { root: { fontWeight: FontWeights.semibold } };

export default function HomePage() {
  return (
    <Stack
      horizontalAlign="center"
      verticalAlign="center"
      verticalFill
      styles={{
        root: {
          width: "960px",
          margin: "0 auto",
          textAlign: "center",
          color: "#605e5c",
        },
      }}
      gap={15}
    >
      <img src={george} alt="logo" />
      <Text variant="large">
        This is for Lily and George and all of our relatives so that they can
        follow their journey's across the USA!
      </Text>
      <Text variant="large" styles={boldStyle}>
        Essential Links
      </Text>
      <Stack horizontal gap={15} horizontalAlign="center">
        <Link href="https://developer.microsoft.com/en-us/fabric">Docs</Link>
        <Link href="https://stackoverflow.com/questions/tagged/office-ui-fabric">
          Stack Overflow
        </Link>
        <Link href="https://github.com/officeDev/office-ui-fabric-react/">
          Github
        </Link>
        <Link href="https://twitter.com/officeuifabric">Twitter</Link>
      </Stack>
    </Stack>
  );
}
