import React from "react";
import { Nav } from "office-ui-fabric-react/lib/Nav";

const navStyles = {
  root: {
    width: 208,
    height: 350,
    boxSizing: "border-box",
    border: "1px solid #eee",
    overflowY: "auto",
  },
};
const navLinkGroups = [
  {
    links: [
      {
        name: "Home",
        url: "./homepage",
        expandAriaLabel: "Expand Home section",
        collapseAriaLabel: "Collapse Home section",
        links: [
          {
            name: "Stories",
            url: "./story",
            key: "key1",
          },
          {
            name: "TODO",
            url: "http://msn.com",
            disabled: true,
            key: "key2",
            target: "_blank",
          },
        ],
        isExpanded: true,
      },
      {
        name: "Documents",
        url: "http://example.com",
        key: "key3",
        isExpanded: true,
        target: "_blank",
      },
      {
        name: "Pages",
        url: "http://msn.com",
        disabled: true,
        key: "key4",
        target: "_blank",
      },
    ],
  },
];

export const NavBar = () => {
  return (
    <Nav
      selectedKey="key3"
      ariaLabel="Nav basic example"
      styles={navStyles}
      groups={navLinkGroups}
    />
  );
};

export default NavBar;
