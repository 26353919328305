export const lightTheme = {
  palette: {
    themePrimary: "#128712",
    themeLighterAlt: "#f1faf1",
    themeLighter: "#cbeccb",
    themeLight: "#a2dba2",
    themeTertiary: "#58b758",
    themeSecondary: "#239623",
    themeDarkAlt: "#107a10",
    themeDark: "#0d670d",
    themeDarker: "#0a4c0a",
    neutralLighterAlt: "#faf9f8",
    neutralLighter: "#f3f2f1",
    neutralLight: "#edebe9",
    neutralQuaternaryAlt: "#e1dfdd",
    neutralQuaternary: "#d0d0d0",
    neutralTertiaryAlt: "#c8c6c4",
    neutralTertiary: "#a19f9d",
    neutralSecondary: "#605e5c",
    neutralPrimaryAlt: "#3b3a39",
    neutralPrimary: "#323130",
    neutralDark: "#201f1e",
    black: "#000000",
    white: "#ffffff",
  },
};

export const darkTheme = {
  palette: {
    themePrimary: "#0dbd71",
    themeLighterAlt: "#010805",
    themeLighter: "#021e12",
    themeLight: "#043922",
    themeTertiary: "#087144",
    themeSecondary: "#0ca663",
    themeDarkAlt: "#20c37c",
    themeDark: "#3ccd8e",
    themeDarker: "#68daa9",
    neutralLighterAlt: "#323232",
    neutralLighter: "#313131",
    neutralLight: "#2f2f2f",
    neutralQuaternaryAlt: "#2c2c2c",
    neutralQuaternary: "#2a2a2a",
    neutralTertiaryAlt: "#282828",
    neutralTertiary: "#c8c8c8",
    neutralSecondary: "#d0d0d0",
    neutralPrimaryAlt: "#dadada",
    neutralPrimary: "#ffffff",
    neutralDark: "#f4f4f4",
    black: "#f8f8f8",
    white: "#333333",
  },
};
