import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { PrimaryButton, Stack } from "office-ui-fabric-react";

const LogoutButton = () => {
  const { logout } = useAuth0();
  return (
    <Stack>
      <PrimaryButton
        text="Log Out"
        onClick={() =>
          logout({
            returnTo: window.location.origin,
          })
        }
      />
    </Stack>
  );
};

export default LogoutButton;
