import React from "react";
import { Stack, FontWeight, TextField } from "office-ui-fabric-react";
import UploadImages from "../utilities/uploadImage";

//const boldStyle = { root: { fontWeight: FontWeights.semibold } };
const stackStyles: Partial<IStackStyles> = { root: { width: 650 } };
const stackTokens = { childrenGap: 50 };
//const dummyText: string = lorem(100);
const columnProps: Partial<IStackProps> = {
  tokens: { childrenGap: 15 },
  styles: { root: { width: 600 } },
};

export default function Story() {
  return (
    <Stack horizontal tokens={stackTokens} styles={stackStyles}>
      <Stack {...columnProps}>
        <TextField
          label="Add your story text here"
          multiline
          autoAdjustHeight
        />
        <UploadImages />
      </Stack>
    </Stack>
  );
}
